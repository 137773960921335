<template>
  <v-card>
    <v-card-title>
      Users
      <div class="flex-grow-1"></div>
      <v-text-field v-model="search" label="Search" single-line hide-details></v-text-field>
    </v-card-title>
    <v-data-table
      v-if="users.length"
      :headers="headers"
      :items="users"
      :items-per-page="30"
      item-key="id"
      class="elevation-1"
      :search="search"
      sort-by="approved"
    >
      <template v-slot:body="{items}">
        <tbody>
          <template v-for="item in items">
            <tr :key="item.id">
              <td>{{ item.first }}</td>
              <td>{{ item.last }}</td>
              <td>{{ item.email }}</td>
              <td>{{ capitalize(item.auth) }}</td>
              <!-- <td>{{ capitalize(item.distributors) }}</td>
              <td>{{ capitalize(item.owner) }}</td>-->
              <!-- <td>
                <div v-for="(loc,i) in item.locations" :key="i">{{ capitalize(loc) }}</div>
              </td>-->
              <td>{{ item.approved ? 'Yes' : 'No' }}</td>
              <td v-if="isSuper || isDistributor">
                <v-btn
                  dark
                  x-small
                  v-if="!item.approved"
                  @click="approveUser(item.id, item.email)"
                >Approve</v-btn>
                <!-- <v-btn
                  dark
                  x-small
                  v-else-if="item.approved && item.auth !== 'super' && item.email !== user.attributes.email"
                  @click="disableUser(item.id)"
                >Disable</v-btn>-->
              </td>
            </tr>
          </template>
        </tbody>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "users",
  data() {
    return {
      search: "",
      headers: [
        { text: "First Name", value: "first" },
        {
          text: "Last Name",
          value: "last"
        },
        { text: "Email", value: "email" },
        { text: "User Type", value: "auth" },
        // { text: "Distributor", value: "distributors" },
        // { text: "Owner", value: "owner" },
        // { text: "Locations", value: "locations" },
        { text: "Approved", value: "approved" },
        { text: "", value: "" }
      ]
    };
  },
  async created() {
    if (this.isSuper) {
      this.$store.dispatch("user/getAllUsers");
    } else if (this.isDistributor) {
      this.$store.dispatch(
        "user/getDistributorUsers",
        this.userDistributorName
      );
    } else if (this.isStore) {
      this.$store.dispatch("user/getStoreUsers", this.userStoreName);
    }
  },
  computed: {
    ...mapState("user", ["user", "users"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isStore",
      "userDistributorName",
      "userStoreName"
    ])
  },
  methods: {
    async approveUser(id, email) {
      await this.$store.dispatch("user/approveUserRegistration", { id, email });
    },
    async disableUser(id) {
      await this.$store.dispatch("user/disableUserRegistration", id);
    },
    capitalize(word) {
      return word.slice(0, 1).toUpperCase() + word.slice(1);
    }
  }
};
</script>

<style lang="scss" scoped>
tr {
  cursor: pointer;
}
</style>